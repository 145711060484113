import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import Header from "../../components/header";
import Footer from "../../components/footer";
import Images from "../../assests/index"
import { FaLanguage } from "react-icons/fa6";
import { MdSpatialTracking } from "react-icons/md";
import { RiCalendarScheduleFill } from "react-icons/ri";
import { FaGooglePlay } from "react-icons/fa";
import playstore from '../../assests/playstore.png'


import { IoMdNotifications } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


const Home = () => {
  const navigate = useNavigate()
  return (
    <>
      {/* <Header /> */}
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
        }}
      >
        {/* Left Side */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: 3,
            p: 3,
            pt:1,
            alignItems:"center"
          }}
        >
          <Box sx={{display:'flex',gap:3}}>
           <Button
      variant="outlined"
      onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.apps.trackmytrain&pli=1", "_blank")}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        borderColor: "black",
        maxWidth:"300px",
        py:2,
        textTransform: "none",
        backgroundColor: "black",
        gap:"18px",
        ":hover": {
          backgroundColor: "black",
          borderColor: "black",
        },
      }}
    >
      {/* <FaGooglePlay size={24} style={{ marginRight: "8px", color: "white" }} /> */}
      <Box component={'img'} src={playstore} width={'35px'}></Box>
      <Box>
        <Typography
          variant="body2"
          sx={{ fontSize: {lg:"15px",md:"15px",sm:'15px',xs:'13px'}, color: "white", lineHeight: 1 }}
        >
          Get it on
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: {lg:"18px",md:"18px",sm:'18px',xs:'13px'}, fontWeight: "bold", color: "white", lineHeight: 1 }}
        >
          Google Play
        </Typography>
      </Box>
          </Button>
              <Button
      variant="outlined"
      onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.apps.trackmytrain&pli=1", "_blank")}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        borderColor: "black",
        maxWidth:"300px",
        py:2,
        textTransform: "none",
        backgroundColor: "black",
        gap:"18px",
        ":hover": {
          backgroundColor: "black",
          borderColor: "black",
        },
      }}
    >
     
      <Box>
        
        <Typography
          variant="body1"
          sx={{ fontSize: {lg:"18px",md:"18px",sm:'18px',xs:'13px'}, fontWeight: "bold", color: "white", lineHeight: 1 }}
        >
         Download Now
        </Typography>
      </Box>
    </Button>
    </Box>
          {/* Image Section */}
          <Box
            component="img"
            src={Images.logo} // Replace with your image path
            alt="Train Image"
            sx={{
              width: "100%",  // Adjust as needed
              maxWidth: "200px",  // Adjust for responsiveness
              borderRadius: "8px",
              margin:"0 auto",
              paddingBottom:"20px"
            }}
          />

          <Typography className="heading-font" variant="h6" textAlign="center" sx={{ fontWeight: "bold", fontSize: "30px" }}>
            Track My Train – Your Trusted Rail Companion
          </Typography>
          <Typography className="heading-font" variant="h6" textAlign="center" sx={{ color: "#606060", fontSize: "15px" }}>
            Real-Time Train Tracking, Schedules, and Updates at Your Fingertips!
          </Typography>
            
            {/* <Button onClick={()=>navigate("/track-train")} sx={{background:"black",color:"white",borderRadius:'20px' ,px:2 ,py:1}}>
              Track Train
            </Button> */}
           
        </Box>

        {/* Right Side */}
        <Box
          sx={{
            flex: 1,
            backgroundImage: `url(${Images.homeImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
            display:{lg:"flex",md:"flex",sm:"none",xs:"none"}
          }}
        ></Box>
      </Box>

      {/* New Section with 4 Cards */}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', px: 5 }}>
        <Grid container spacing={3} justifyContent="center">
          {/* Card 1 */}
          <Grid item xl={3} lg={6} md={6} sm={6} xs={12} sx={{}}>
            <Box
              sx={{
                padding: 2,
                textAlign: "center",
                boxShadow: 3,
                height: 320,
                width: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundImage: `url(${Images.trackTrain})`, // Replace with your image URL
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "white", // Ensures text is visible
                borderRadius: 6,
                position: "relative",
                cursor: "pointer"
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Add a semi-transparent overlay
                  borderRadius: 6,
                }}
              ></Box>
              <Typography variant="h6" sx={{ mt: 2, zIndex: 1 }}>
                Real-Time Train Tracking
              </Typography>



              <MdSpatialTracking style={{ color: "white", width: 100, height: 100, zIndex: 1 }} />

              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  zIndex: 1,
                }}
              >
                <li>Get live updates on train locations</li>
                <li>Track delays and estimated arrival times</li>
                <li>Never miss a train again!</li>
              </ul>
            </Box>
          </Grid>

          {/* Card 2 */}
          <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
            <Box
              sx={{
                padding: 2,
                textAlign: "center",
                boxShadow: 3,
                height: 320,
                width: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundImage: `url(${Images.trainSchedule})`, // Replace with your image URL
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "white",
                borderRadius: 6,
                position: "relative",
                cursor: "pointer"

              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Add a semi-transparent overlay
                  borderRadius: 6,
                }}
              ></Box>
              <Typography variant="h6" sx={{ mt: 2, zIndex: 1 }}>
                Train Schedules & Routes
              </Typography>
              <RiCalendarScheduleFill style={{ color: "white", width: 100, height: 100, zIndex: 1 }} />

              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  zIndex: 1,
                }}
              >
                <li>Browse comprehensive train schedules</li>
                <li>Plan your journey with ease</li>
              </ul>
            </Box>
          </Grid>

          {/* Card 3 */}
          <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
            <Box
              sx={{
                padding: 2,
                textAlign: "center",
                boxShadow: 3,
                height: 320,
                width: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundImage: `url(${Images.notificationImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "white",
                borderRadius: 6,
                position: "relative",
                cursor: "pointer"

              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: 6,
                }}
              ></Box>
              <Typography variant="h6" sx={{ mt: 2, zIndex: 1 }}>
                Notifications & Alerts
              </Typography>
              <IoMdNotifications style={{ color: "white", width: 100, height: 100, zIndex: 1 }} /> {/* Increased width and height */}

              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  zIndex: 1,
                }}
              >
                <li>Stay informed about delays and cancellations</li>
                <li>Receive pre-arrival notifications</li>
              </ul>
            </Box>
          </Grid>

          {/* Card 4 */}
          <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
            <Box
              sx={{
                padding: 2,
                textAlign: "center",
                boxShadow: 3,
                height: 320,
                width: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundImage: `url(${Images.multiLanguages})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "white",
                borderRadius: 6,
                position: "relative",
                cursor: "pointer"
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: 6,
                }}
              ></Box>
              <Typography variant="h6" sx={{ mt: 2, zIndex: 1 }}>
                Multi-Language Support
              </Typography>
              <FaLanguage style={{ color: "white", width: 100, height: 100, zIndex: 1 }} />
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  zIndex: 1,
                }}
              >
                <li>Available in English & Urdu languages</li>
                <li>Cater to a diverse user base</li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>



      <Footer />
    </>
  );
}

export default Home;
