import moment from "moment/moment";

// *Email Regex
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//* get selected train kmlUrl
export const getTrainKmlUrl = (trainName) => {
  let url = "";
  try {
    const baseUrl = "https://smartfuture.ae/kml/";

    const ext = ".kml";
    const name = trainName.trim().replace(/ /g, "-");
    url = baseUrl + name + ext;

    return url;
  } catch (e) {
    url.error = e;
  } finally {
    return url;
  }
};

//* get last update from socket
export const getLastUpdate = (dateTime) => {
  try {
    let utcTime = new Date(+dateTime * 1000);
    let utcHour = utcTime.getHours();
    let utcMinute = utcTime.getMinutes();

    let currTime = new Date();
    let currHour = currTime.getHours();
    let currMinute = currTime.getMinutes();

    let hours = Math.abs(currHour - utcHour);
    let minutes = Math.abs(currMinute - utcMinute);
    let lastUpdate;

    if (hours === 0 && minutes > 0) {
      lastUpdate = minutes + " min ago";
    } else if (minutes === 0) {
      lastUpdate = "just now";
    } else {
      lastUpdate = hours + " hr " + minutes + " min ago";
    }
    return lastUpdate;
  } catch (e) {
    // console.log("🚀 ~ file: index.js:46 ~ getLastUpdate ~ e:", e);
  }
};

//* convert json number into time format
export const timeConvert = (num) => {
  try {
    let early = "";

    if (num < 0) {
      early = "early";
    }

    let hours = Math.floor(Math.abs(num) / 60);
    let minutes = Math.abs(num) % 60;
    
    let time;

    if (hours === 0) {
      time = minutes + " min " + early;
    } else if (minutes === 0) {
      time = hours ? hours + " hr" + "0 min" : "0 min";
    } else {
      time = hours + " hr " + minutes + " min " + early;
    }

    return time;
  } catch (e) {
    // console.log("🚀 ~ file: index.js:73 ~ timeConvert ~ e:", e);
  }
};

//* get date and  month from ride id
export const getDateMonth = (rideID) => {
  try {
    let ddMM = rideID.substring(0, rideID.length - 4).slice(-4);
    let date = ddMM.substring(0, ddMM.length - 2);
    let month = ddMM?.slice(-2);
    month =
      month.substring(0, month.length - 1) === "0" ? month.slice(-1) : month;

    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let selectedMonthName = months[month - 1];

    let dateMonth = date + " " + selectedMonthName;

    return dateMonth;
  } catch (e) {
    // console.log("🚀 ~ file: index.js:92 ~ getDateMonth ~ e:", e);
  }
};

//* get all stations of selected train
export const getTrainNextStations = (stationArray, stationID) => {
  try {
    // Find the index of the given stationID in the array
    const startIndex = stationArray?.findIndex(
      (station) => station["StationDetailsId"] === stationID
    );

    if (startIndex === -1) {
      // console.error("Station ID not found in the station array");
      return [];
    }

    // Return all stations starting from the next station
    const nextStations = stationArray.slice(startIndex + 1);
    // console.log(nextStations, "nextStations");
    return nextStations;
  } catch (e) {
    // console.error("Error fetching next stations:", e);
    return [];
  }
};
// export const getTrainNextStations = (stationArray, stationID) => {
//   let result;
//   let i;
//   try {
//     for (i = 0; i <= stationArray.length; i++) {
//       if (stationArray[i]['StationDetailsId'] === stationID) {
//         result = stationArray[i]['StationName'];
//         break;
//       }
//     }
//     return result;
//   } catch (e) {
//     result.error = e;
//   } finally {
//     return result;
//   }
// }

export const getTrainPrevStation = (stationArray, stationID) => {
  let result;
  let i;
  try {
    for (i = 0; i <= stationArray.length; i++) {
      if (stationArray[i]["StationDetailsId"] === stationID) {
        result = stationArray[i]["StationName"];
        break;
      }
    }
    return result;
  } catch (e) {
    result.error = e;
  } finally {
    return result;
  }
};

//* calculate train estimated arrival time

export const getEstArrivalTime = (stationArray, stationID, delayTime) => {
  let estdTimes = [];
  // console.log(delayTime);
  // console.log(stationArray);
  try {
    
    const startIndex = stationArray.findIndex(
      (station) => station["StationDetailsId"] === stationID
    );
    // console.log(startIndex);
    if (startIndex === -1) {
      // console.error(`Station with ID ${stationID} not found.`);
      return [];
    }

    for (let i = startIndex; i < stationArray.length; i++) {
      const station = stationArray[i];
      const arrivalTimeRaw = station["ArrivalTime"];

      const arrivalTime = moment(arrivalTimeRaw, "HH:mm");

      const estArrivalTime = arrivalTime.add(delayTime, "minutes");

      const estdTimeArrival = estArrivalTime.format("hh:mm a");

      estdTimes.push({
        ...station,
        EstArrivalTime: estdTimeArrival,
      });
    }

    // console.log(estdTimes ,"estdTimes")
    return estdTimes;
  } catch (e) {
    // console.error("Error calculating estimated arrival times:", e);
    return [];
  }
};

//* calculate train estimated arrival time for info window
export const getInfoWindowEstArrivalTime = (arrivalTime, delayTime) => {
  let estdTimeArrival;
  let format = "";
  try {
    let split = arrivalTime.split(":");
    let splitTime = (+split[0] % 12 || 12) * 60 + +split[1];

    let num = parseInt(splitTime) + parseInt(delayTime);
    let hours = Math.floor(num / 60);
    let minutes = num % 60;

    if (hours > 12) {
      format = " pm";
    } else {
      if (+split[0] > 12) {
        format = " pm";
      } else {
        format = " am";
      }
    }

    minutes = minutes <= 9 ? "0" + minutes : minutes;

    hours = +hours % 12 || 12; // Adjust hours
    estdTimeArrival = hours + ":" + minutes + format;

    return estdTimeArrival;
  } catch (e) {
    estdTimeArrival.error = e;
  } finally {
    return estdTimeArrival;
  }
};

//* convert time format
export const convertTimeFormat = (time) => {
  let departureTime = "";
  let format = "";
  try {
    let split = time.split(":");

    if (+split[0] <= 12) {
      format = " am";
      departureTime = time + format;
    } else {
      split[0] = +split[0] % 12 || 12; // Adjust hours
      format = " pm";
      departureTime = split[0] + ":" + split[1] + format;
    }

    return departureTime;
  } catch (e) {
    departureTime.error = e;
  } finally {
    return departureTime;
  }
};

// *get last update for single train in time format HH:MM
export const getTrainLastUpdate = (dateTime) => {
  try {
    let utcTime = new Date(+dateTime * 1000);
    let utcHour = utcTime.getHours();
    let utcMinute = utcTime.getMinutes();

    let lastUpdate; 
    let format;

    let num = (+utcHour % 12 || 12) * 60 + +utcMinute;
    let hours = Math.floor(num / 60);
    let minutes = num % 60;
    minutes = minutes <= 9 ? "0" + minutes : minutes;

    if (hours > 12) {
      format = " pm";
    } else {
      if (+utcHour > 12) {
        format = " pm";
      } else {
        format = " am";
      }
    }
    hours = +hours % 12 || 12;
    lastUpdate = hours + ":" + minutes + format;

    return lastUpdate;
  } catch (e) {
    // console.log("🚀 ~ file: index.js:245 ~ getTrainLastUpdate ~ e:", e);
  }
};

//* get all stations of selected train
export const getTrainStation = (stationArray, stationID) => {
  let result;
  let i;
  try {
    for (i = 0; i <= stationArray.length; i++) {
      if (stationArray[i]["StationDetailsId"] === stationID) {
        result = stationArray[i]["StationName"];
        break;
      }
    }
    return result;
  } catch (e) {
    result.error = e;
  } finally {
    return result;
  }
};
