import React, { Fragment, useEffect, useState } from 'react';
import { Close, Logout, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import { AppBar, Autocomplete, Avatar, Box, CircularProgress, Dialog, DialogTitle, Divider, IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, tableCellClasses } from '@mui/material';
import Images from '../../assests/index';
// import Colors from '../../';
// import { useAuth } from 'context/UseContext';
// import { ErrorToaster, SuccessToaster } from 'components/toaster/Toaster';
// import AuthServices from 'apis/auth/AuthServices';
// import { useForm } from 'react-hook-form';

import { useRef } from 'react';

import styled from '@emotion/styled';
// import { CircleLoading } from 'components/loaders/Loaders';

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: 700,
    border: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#213d7a',
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
  },
}));

function DropDown({ anchorEl, openDropdown, handleClose }) {

//   const { user, userLogout } = useAuth();

//   const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
//   const password = useRef({});
//   password.current = watch("password", "");

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  // *For Show Password Toggle
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   const [openRegister, setOpenRegister] = useState(false);
//   const [openUserList, setOpenUserList] = useState(false);

//   const [usersList, setUsersList] = useState([]);

  // *For Get Users List
//   const getUsersList = async () => {
//     setLoader(true)
//     try {
//       const result = await AuthServices.getUsers()
//       setUsersList(result)
//     } catch (error) {
//       ErrorToaster(error)
//     } finally {
//       setLoader(false)
//     }
//   }

  // *For Register User
//   const userRegister = async (formData) => {
//     setLoading(true)
//     try {
//       let obj = {
//         Email: formData?.email,
//         UserName: formData?.userName,
//         Password: formData?.password,
//         ConfirmPassword: formData?.confirmPassword
//       }
//       await AuthServices.register(obj)
//       SuccessToaster('User Created Successfully')
//       setOpenRegister(false)
//     //   getUsersList()
//       reset()
//     } catch (error) {
//       ErrorToaster(error)
//     } finally {
//       setLoading(false)
//     }
//   }

//   useEffect(() => {
//     if (user?.userName === 'cradmin') {
//       getUsersList()
//     }
//   }, [openDropdown]);

  return (
    <Fragment>

      {/* *For Users List */}
      {/* <Dialog
        maxWidth="lg"
        open={openUserList}
        sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
      >
        <IconButton color="primary" onClick={() => setOpenUserList(false)} sx={{ position: 'absolute', right: 13, top: 13 }}>
          <Close />
        </IconButton>
        <Box sx={{ my: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'centerd' }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            User Lists
          </Typography>
          <PrimaryButton
            title="Create user"
            btnColor={"green"}
            onClick={() => setOpenRegister(true)}
          />
        </Box>
        <TableContainer component={Paper} sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: 2, maxHeight: { xs: 'auto', sm: 'auto', md: 'calc(100vh - 300px)' } }}>
          <Table sx={{ minWidth: 650 }} aria-label="user table">
            <TableHead>
              <TableRow>
                <Cell>Username</Cell>
                <Cell>Email</Cell>
                <Cell>Created At</Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loader ? (
                <Fragment>
                  {usersList?.length > 0 ? (
                    <Fragment>
                      {usersList.map((row, index) => (
                        <Row key={index} sx={{ bgcolor: index % 2 !== 0 && "white", '&:last-child td, &:last-child th': { border: 0 } }}>
                          <Cell>{row.UserName}</Cell>
                          <Cell>{row.Email}</Cell>
                          <Cell>{moment(row?.CreatedDate).format('DD MMM YYYY')}</Cell>
                        </Row>
                      ))}
                    </Fragment>
                  ) : (
                    <Row>
                      <Cell colSpan={3} align="center" sx={{ fontWeight: 600 }}>
                        No user found
                      </Cell>
                    </Row>
                  )}
                </Fragment>
              ) : (
                <Row>
                  <Cell colSpan={3} align="center" sx={{ fontWeight: 600 }}>
                    <CircleLoading />
                  </Cell>
                </Row>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog> */}

      {/* For User Register */}
      {/* <Dialog
        maxWidth="xs"
        open={openRegister}
        sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
      >
        <IconButton color="primary" onClick={() => setOpenRegister(false)} sx={{ position: 'absolute', right: 13, top: 13 }}>
          <Close />
        </IconButton>
        <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>Register User</DialogTitle>
        <Box component="form" onSubmit={handleSubmit(userRegister)} >
          <Box sx={{ mb: 1 }}>
            <InputField
              label={'Email'}
              type="email"
              placeholder={'Enter email address.'}
              error={errors?.email?.message}
              register={register("email", {
                required: 'Please enter user name.',
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email.',
                }
              })}
            />
            <InputField
              label={'Username'}
              placeholder={'Enter user name.'}
              error={errors?.userName?.message}
              register={register("userName", {
                required: 'Please enter user name.',
              })}
            />
            <InputField
              label={'Password'}
              type={showPassword ? 'text' : 'password'}
              placeholder={'password'}
              error={errors?.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              register={register("password", {
                required: 'Please enter your password',
                minLength: {
                  value: 6,
                  message: "password must contain 6 characters"
                }
              })}
            />
            <InputField
              label={'Confirm Password'}
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder={'confirmPassword'}
              error={errors?.confirmPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              register={register("confirmPassword", {
                required: 'Please enter confirm password',
                validate: value => value === password.current || "Confirm Password does not match"
              })}
            />
          </Box>
          <PrimaryButton
            type={"submit"}
            title="save"
            loading={loading}
            btnColor={Colors.primaryGradient}
          />
        </Box>
      </Dialog> */}

      <Menu
        anchorEl={anchorEl}
        open={openDropdown}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '.MuiSvgIcon-root': {
              width: 20,
              height: 20,
              ml: 0.5,
              mr: 0.5,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        {/* {user?.userName === 'cradmin' &&
          <MenuItem >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            Users List
          </MenuItem>
        } */}
        <MenuItem >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

function Header({ loading, isTrain, trains, getTrains, getTrainStatus, locomotive, getAllLocomotive ,setSelectedTrain,selectedTrain}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  const liveTracking = [
    { id: 'all', label: 'All Trains Live Tracking' },
    { id: 'locomotive', label: 'All Locomotive Live Tracking' },
    { id: 'up', label: 'All Up Trains Live Tracking' },
    { id: 'down', label: 'All Down Trains Live Tracking' },
  ]

  const [selectedCategory, setSelectedCategory] = useState(liveTracking[0]);

  const [allTrains, setAllTrains] = useState(trains);
//   const [selectedTrain, setSelectedTrain] = useState();

  const [allLocomotive, setAllLocomotive] = useState(locomotive);

  const handleTrainTracking = (item) => {
    try {
      const index = liveTracking.findIndex((e) => e.label === item.label)
      setSelectedCategory(liveTracking[index]);
      if (item?.id === 'locomotive') {
        getAllLocomotive()
      } else {
        getTrains(liveTracking[index]?.id)
      }
      setSelectedTrain();
    } catch (error) {
    //   ErrorToaster(error)
    }
  }

  const handleSearchTrain = (item) => {
    // console.log("item" ,item)
    try {
      if (item) {
        const index = allTrains.findIndex((e) => e.TrainName === item.TrainName)
        setSelectedTrain(allTrains[index]);
        getTrainStatus(item, 'train-status')
      } else {
        setSelectedTrain();
        getTrainStatus('', 'all-dtrains')
      }
    } catch (error) {
    //   ErrorToaster(error)
    }
  }

  useEffect(() => {
    setAllTrains(trains);
    localStorage.setItem('trains', JSON.stringify(trains));
  }, [trains]);

  // useEffect(() => {
  //   setAllLocomotive(locomotive)
  //   localStorage.setItem('locoTrains', JSON.stringify(locomotive));
  // }, [locomotive]);

  return (
    <Box sx={{ my: 2.5, mx: 2 }}>
      <AppBar elevation={0} position="static" sx={{ bgcolor: 'transparent' }}>
        <Toolbar sx={{ px: { xl: 6, xs: 3 }, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="img" src={Images.logo} sx={{ height: '70px' }} />
            <Typography variant="h5" sx={{ color: "black",mt:4,fontSize:{md:"24px",sm:"20px",xs:"20px"},fontFamily:"Poppins"}}>Track My Train</Typography>
          </Box>
          
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ cursor: 'pointer' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Avatar
                src={Images.logo}
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: "white",
                  border: `1px solid green`,
                  '.MuiAvatar-img': { objectFit: 'contain' }
                }}
              />
            </Box>
          </Box> */}
          <DropDown anchorEl={anchorEl} openDropdown={openDropdown} handleClose={() => setAnchorEl(null)} />
        </Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 0.8 ,mt:3 }} >
            {/* <Autocomplete
              disablePortal
              disableClearable
              value={selectedCategory}
              options={liveTracking}
              onChange={(event, newValue) => {
                handleTrainTracking(newValue);
              }}
              sx={{ width: 280, bgcolor:"white", borderTopLeftRadius: 26, borderBottomLeftRadius: 26 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Category"
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  }}
                />
              )}
            />
            <Divider orientation="vertical" flexItem /> */}
            <Autocomplete
              disablePortal
              value={selectedTrain}
              // options={isTrain ? allTrains : allLocomotive}
              options={isTrain ? allTrains ?? [] : allLocomotive ?? []} 
              getOptionLabel={(option) => isTrain ? option?.TrainName : option?.LocomotiveNumber}
              getOptionDisabled={option => option?.IsLive === true ? false : true}
              onChange={(event, newValue) => {
                handleSearchTrain(newValue);
              }}
              loading={loading}
              sx={{ width: 500, bgcolor: "white", borderRadius: 26 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Trains"
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  }}
                  InputProps={{
                    ...params?.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
      </AppBar>
    </Box>
  );
}

export default Header;