import io from 'socket.io-client';

// const SOCKET_URL = 'https://rido.mangotech-api.com';
// const SOCKET_URL = 'http://119.159.226.139:8098';
// const SOCKET_URL = 'http://socket.pakraillive.com:3019/';
const SOCKET_URL =process.env.REACT_APP_SOCKET_API_KEY
// const SOCKET_URL = 'https://socket2.pakraillive.com';
// const SOCKET_URL = 'http://socket2.pakraillive.com:3019/';
// const SOCKET_URL = '108.181.157.59:3019/';

export const socketConfig = () => {

    //const socket = io("http://198.71.60.173:8098",
        const socket = io(SOCKET_URL,
            {  

                rejectUnauthorized: false,
                reconnection: true,   
                    // extraHeaders: {
                    //   "Authorization": encodeURI("tr@ckO||"), 
                    
                    // },       
            });
        // socket.on("connect", () => {
        //     console.log("Connected to the WebSocket server");
        // });
        
        // socket.on("connect_error", (error) => {
        //     console.error("WebSocket connection error:", error);
        // });
        
        // socket.on("disconnect", () => {
        //     console.log("Disconnected from the WebSocket server");
        // });

    return socket;
}