import { useEffect, useState } from 'react';
import devtoolsDetect from 'devtools-detect';
import { createTheme, ThemeProvider, CssBaseline, GlobalStyles } from "@mui/material";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./pages/home/index";
import Privacy from './pages/privacy';
import TrackTrain from './pages/Tracking/index';
import { ToasterContainer } from './components/toaster/Toaster';
import { Analytics } from "@vercel/analytics/react"

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
});

function App() {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(devtoolsDetect.isOpen);
  useEffect(() => {
    const checkDevTools = () => {
      if (devtoolsDetect.isOpen) {
        setIsDevToolsOpen(true);
        window.location.href = "https://www.google.com";
      }
    };

    // Har 1 second baad check karega
    const interval = setInterval(checkDevTools, 1000);

    const handleContextMenu = (e) => e.preventDefault();
    const handleKeyDown = (e) => {
      if (
        e.keyCode === 123 || // F12
        (e.ctrlKey && e.shiftKey && e.keyCode === 73) || 
        (e.ctrlKey && e.shiftKey && e.keyCode === 74) || 
        (e.ctrlKey && e.keyCode === 85)
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      clearInterval(interval);
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToasterContainer />
      <Analytics />
      <GlobalStyles styles={{ body: { fontFamily: "Poppins, Arial, sans-serif" } }} />
      <BrowserRouter>
        <div className="App ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/track-train" element={<TrackTrain />} />
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
