import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
  styled,
  Paper,
  Stack,
  Divider,
  Typography,
  Container,
  ListItem,
  ListItemButton,
  ListItemText,
  List,
  CircularProgress,
} from "@mui/material";
import AuthServices from "../../apis/AuthServices";
import { Controller, useForm } from "react-hook-form";
import {
  LocationOn,
  Schedule,
  Train,
  AccessTime,
  Speed,
  Update,
} from "@mui/icons-material";
import { socketConfig } from "../../config/axios/socket";
import {
  getDateMonth,
  getEstArrivalTime,
  getTrainKmlUrl,
  getTrainNextStation,
  getTrainNextStations,
  getTrainStation,
  timeConvert,
} from "../../utils";
import Header from "./Header";
import Images from "../../assests";
import SimpleDialog from "../../components/Dialog";
import moment from "moment";
import { ErrorToaster } from "../../components/toaster/Toaster";

var timer;
let socket = null;
let selectedRideID = null;
let isDefaultCenter = false;

const TimelineContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: "16px",
  "&::before": {
    content: '""',
    position: "absolute",
    left: "28px",
    top: "0",
    bottom: "0",
    width: "2px",
    background: theme.palette.grey[300],
    zIndex: 0,
  },
}));

const StationCard = styled(Paper)(({ theme, isFirst }) => ({
  position: "relative",
  padding: "16px",
  marginBottom: "16px",
  marginLeft: "24px",
  borderRadius: "8px",
  boxShadow: theme.shadows[1],
  "&::before": {
    content: '""',
    position: "absolute",
    left: "-22px",
    top: "50px",
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: isFirst
      ? theme.palette.primary.light
      : theme.palette.success.main,
    border: `4px solid ${theme.palette.background.paper}`,
    zIndex: 1,
  },
}));

const InfoRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  gap: "8px",
  "&:last-child": {
    marginBottom: 0,
  },
  [theme.breakpoints.down("sm")]: {
   fontSize:"15px"
  },
}));


const GreenText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize:"15px"
   },
}));

const TrainTrackingDropdown = () => {
  const [allTrains, setAllTrains] = useState("All Trains Live Tracking");
  const [selectedTrain, setSelectedTrain] = useState(null);
  const [trains, setTrains] = useState([]);
  const [stations, setStations] = useState([]);
  const [kmlUrl, setKmlUrl] = useState(
    "https://smartfuture.ae/kml/All-Trains.kml"
  );
  const [trainDetail, setTrainDetail] = useState("");
  const [trainMakers, setTrainMakers] = useState([]);
  const [trainStations, setTrainStations] = useState([]);
  const [trainPosition, setTrainPosition] = useState("all");
  const [nextStationId, setNextStationId] = useState();
  const [trainDateList, setTrainDateList] = useState([]);
  const [storeTrainData, setStoreTrainData] = useState();
  const [isTrain, setIsTrain] = useState(true);
  const [isShowStations, setIsShowStations] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [allLocomotive, setAllLocomotive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stationsLoading, setStationsLoading] = useState(false);


  const handleClose = (id) => {
    try {
      clearTimeout(timer);
      let obj = storeTrainData;
      // obj.TrainId = id;
      getTrainStatus(obj, "train-status", id);
      setOpenDialog(false)

    } catch (error) {
      ErrorToaster(error);
    }
  };

  const getTrainStationsByTrainId = async (data) => {
    try {
      setStationsLoading(true)
      const { Response } = await AuthServices.getTrainsById(data?.TrainId);
      setTrainStations(Response);
      setStationsLoading(false)

    } catch (error) {
      //   ErrorToaster(error)
    }
  };
  const getTrainStatus = (data, event, id) => {
    try {
      isDefaultCenter = false;
      selectedRideID = id;
      let trainNumbers;
      if (socket?.disconnect) {
        socket.off("train-status", (e) => null);
        socket.off("all-dtrains", (e) => null);

        clearTimeout(timer);
      }

      trainNumbers = [data?.TrainNumber];

      socket = socketConfig();
      let isSocketConnected;
      const trainNumber = {
        trainId: trainNumbers.map((item) => item + "9900"),
        "track-train": encodeURI("tr@ckO||"),
      };
      socket.on("connect", () => {
        socket.emit(event, trainNumber);
        isSocketConnected = true;
      });

      socket.on("disconnect", () => {
        isSocketConnected = false;
      });

      socket.on("connect_error", (err) => {

        setTimeout(() => {
          socket.connect();
        }, 1000);
      });

      if (isSocketConnected == false) {
        socket.connect();
      }

      socket.on(event, (obj) => {
        let arr = [];
        if (obj === undefined || obj === null || JSON.stringify(obj) === "{}") {
          setTrainStations([]);

          ErrorToaster("Currently this train is not live.");
          return;
        } else {
          let rideID;
          let objDetail = [];
          let objKeys = [];
          let objLength = Object.keys(obj).length;
          objDetail.push(obj);
          let innerObjDetail = [];
          let innerObjLength;
          let trainName;
          let trainId;

          if (event === "all-dtrains") {
            for (let i = 0; i < objLength; i++) {
              rideID = objDetail.flatMap((x) => Object.keys(x))[i];
              innerObjLength = Object.keys(obj[rideID]).length;
              innerObjDetail.push(obj[rideID]);
              if (innerObjLength > 0) {
                // *Fetch Train Name from All Trains Array
                if (isTrain) {
                  for (let j = 0; j < trains.length; j++) {
                    // *If rideId is include in UP/DOWN TraindIDArray
                    let newRideId = rideID.replace("9900", "");
                    if (trainNumbers.includes(+newRideId)) {
                      if (+newRideId === trains[j]["TrainNumber"]) {
                        trainName = trains[j]["TrainName"];
                        trainId = trains[j]["TrainId"];
                        // *Get Key Wise Value From Train Objects
                        for (let k = 0; k < innerObjLength; k++) {
                          let key = innerObjDetail.flatMap((x) =>
                            Object.keys(x)
                          )[k];
                          let lat = +obj[rideID][key]["lat"];
                          let lng = +obj[rideID][key]["lon"];
                          let lastUpdate = +obj[rideID][key]["last_updated"];
                          let speed = +obj[rideID][key]["sp"];
                          let lateBy = +obj[rideID][key]["late_by"];
                          let nextStationID = +obj[rideID][key]["next_station"];
                          let prevStationID = +obj[rideID][key]["prev_station"];
                          let nextStop = obj[rideID][key]["next_stop"];
                          let trainIcon = obj[rideID][key]["icon"];
                          let isFlagged = obj[rideID][key]["isFlagged"];
                          arr.push({
                            // trainIcon: trainPosition === 'up' ? Images?.upTrain : Images?.dnTrain,
                            rideId: rideID,
                            trainIcon: trainIcon,
                            latitude: lat,
                            longitude: lng,
                            lastUpdate: lastUpdate,
                            speed: speed,
                            TrainName: trainName,
                            TrainId: trainId,
                            delay: lateBy,
                            nextStationId: nextStationID,
                            prevStationId: prevStationID,
                            nextStop: nextStop,
                            depDate: key,
                            isFlagged: isFlagged,
                          });
                        }
                      }
                    }
                  }
                } else {
                  for (let j = 0; j < allLocomotive.length; j++) {
                    // *If rideId is include in UP/DOWN TraindIDArray
                    if (trainNumbers.includes(+rideID)) {
                      if (+rideID === allLocomotive[j]["TrainNumber"]) {
                        trainName = allLocomotive[j]["LocomotiveNumber"];
                        // *Get Key Wise Value From Train Objects
                        for (let k = 0; k < innerObjLength; k++) {
                          let key = innerObjDetail.flatMap((x) =>
                            Object.keys(x)
                          )[k];
                          let lat = +obj[rideID][key]["lat"];
                          let lng = +obj[rideID][key]["lon"];
                          let lastUpdate = +obj[rideID][key]["last_updated"];
                          let speed = +obj[rideID][key]["sp"];
                          let lateBy = +obj[rideID][key]["late_by"];
                          let nextStationID = +obj[rideID][key]["next_st"];
                          arr.push({
                            trainIcon: Images.logo,
                            latitude: lat,
                            longitude: lng,
                            lastUpdate: lastUpdate,
                            speed: speed,
                            LocomotiveNumber: trainName,
                            delay: lateBy,
                            nextStationId: nextStationID,
                            depDate: key,
                          });
                        }
                      }
                    }
                  }
                }
                innerObjDetail = [];
              }
            }
            setTrainMakers(arr);
          } else {
            if (objLength > 1) {
              if (isTrain) {
                if (selectedRideID === null) {
                  setOpenDialog(true);
                  setStoreTrainData(data);
                  // console.log(data , 'elsw')
                  for (let i = 0; i < objLength; i++) {
                    rideID = objDetail.flatMap((x) => Object.keys(x))[i];
                    objKeys.push({
                      rideID: rideID,
                      departureDate: getDateMonth(rideID),
                      trainID: data.TrainId,
                    });
                  }
                  setTrainDateList(objKeys)
                  // setOpenDialog(false);
                } else {
                  updateTrainStatus(data, obj, selectedRideID);
                  // console.log(data,'---data')
                }
              } else {
                for (let i = 0; i < objLength; i++) {
                  rideID = objDetail.flatMap((x) => Object.keys(x))[i];
                  if (rideID == data?.TrainRideId) {
                    updateTrainStatus(data, obj, rideID);
                    // console.log(data ,"data332")
                  }
                }
              }
            } else {
              // rideID = objDetail.flatMap(x => Object.keys(x))[0];
              rideID = Object.keys(obj)[0];
              // console.log(data ,"data339")

              updateTrainStatus(data, obj, rideID);
            }
          }
          timer = setTimeout(() => {
            socket.emit(event, trainNumber);
          }, 10000);
        }
      });
    } catch (e) {
      ErrorToaster("🚀 ~ file: index.js:304 ~ getTrainStatus ~ e:", e);
    }
  };
  const getAllTrains = async (position) => {
    setLoading(true);
    try {
      isDefaultCenter = false;
      setIsTrain(true);
      const upTrains = [];
      const downTrains = [];
      const { Response } = await AuthServices.getTrains();
      setTrainStations([]);
      setTrainMakers([]);
      if (position === "all") {
        setTrains(Response);
        return;
      } else {
        Response.forEach((element) => {
          if (element.IsUp) {
            upTrains.push(element);
          } else {
            downTrains.push(element);
          }
        });
        if (position === "up") {
          setTrains(upTrains);
        } else {
          setTrains(downTrains);
        }
      }
    } catch (error) {
      //   ErrorToaster(error)
    } finally {
      setLoading(false);
    }
  };
  // console.log(trainDetail ,"rea")
  const updateTrainStatus = (data, trainObj, rideID) => {
    // console.log(selectedTrain ,"datagtrgtrg")
    // console.log(data ,"data")
    try {
      let spreadData = data;
      selectedRideID = rideID;
      spreadData.depDate = rideID;
      spreadData.latitude = parseFloat(trainObj[rideID]["lat"]);
      spreadData.longitude = parseFloat(trainObj[rideID]["lon"]);
      spreadData.lastUpdate = trainObj[rideID]["last_updated"];
      spreadData.delay = trainObj[rideID]["late_by"];
      spreadData.speed = trainObj[rideID]["sp"];
      spreadData.nextStationId = +trainObj[rideID]["next_station"];
      spreadData.prevStationId = +trainObj[rideID]["prev_station"];
      spreadData.nextStop = trainObj[rideID]["next_stop"];
      //   spreadData.trainIcon = data?.IsUp ? Images.upTrain : Images.dnTrain
      spreadData.isFlagged = trainObj[rideID]["isFlagged"];

      let nextStationID = +trainObj[rideID]["next_station"];
      (async () => {
        const { Response } = await AuthServices.getTrainsById(data?.TrainId);
        const filter = Response?.find(
          (e) => e.StationDetailsId === nextStationID
        );
        setNextStationId(filter?.OrderNumber);
      })();
      setTrainDetail(spreadData);
      if (isDefaultCenter === false) {
        isDefaultCenter = true;
      }
      setTrainMakers([spreadData]);
    } catch (e) {
      // console.log("🚀 ~ file: index.js:335 ~ updateTrainStatus ~ e:", e);
    }
  };
  const getAllLocomotive = async () => {
    setLoading(true);
    try {
      const { Response } = await AuthServices.getAllLocomotive();
      setAllLocomotive(Response);
      setIsTrain(false);
      setTrainMakers([]);
      setTrainStations([]);
    } catch (error) {
      //   ErrorToaster(error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTrains("all");
  }, []);

  // useEffect(() => {
  //   getTrainStatus("", "all-dtrains", null);
  // }, [trains, allLocomotive]);

  return (
    <>
      <Container>
        <SimpleDialog
          open={openDialog}
          title={"Select Train Start Date"}
          onClose={() => setOpenDialog(false)}
        >
          <List>
            {trainDateList.map((date, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => handleClose(date?.rideID)}>
                  <ListItemText primary={date?.departureDate} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </SimpleDialog>

        <Box
          sx={{ position: "absolute", left: 0, top: 0, width: 1, zIndex: 5 }}
        >
          <Header
            loading={loading}
            isTrain={isTrain}
            trains={trains}
            locomotive={allLocomotive}
            getTrains={(position) => {
              getAllTrains(position);
              setTrainPosition(position);
            }}
            getTrainStatus={(data, socketEvent) => {
              getTrainStationsByTrainId(data);
              getTrainStatus(data, socketEvent, null);
              setIsShowStations(true);
            }}
            getAllLocomotive={() => getAllLocomotive()}
            setSelectedTrain={(data) => setSelectedTrain(data)}
            selectedTrain={selectedTrain}
          />
        </Box>
        <Box sx={{ mt: 22  }}>
        {stationsLoading == true ? ( <Box sx={{mt: 32,display:'flex' ,justifyContent:"center"}}>
          <CircularProgress sx={{ color: '#40fd41' }} />

          </Box>) :(
 !openDialog &&
          getTrainNextStations(trainStations, trainDetail?.prevStationId)
            ?.length > 0 ? (
            <>
              <TimelineContainer>
                {getTrainNextStations(
                  trainStations,
                  trainDetail?.prevStationId
                ).map((station, index) => (
                  // console.log(station)
                  <StationCard isFirst={index === 0} elevation={1} key={index}>
                    <Stack spacing={1}>
                      <InfoRow>
                        <Box sx={{display:"flex"}}>

                        <LocationOn sx={{ color: "success.main",fontSize:{lg:"22px",md:'22px',sm:'22px',xs:"17px"} }} />
                        <GreenText sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>Next Station</GreenText>
                        </Box>
                        <Typography sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}> {station?.StationName}</Typography>
                      </InfoRow>
                      <InfoRow>
                      <Box sx={{display:"flex"}}>
                        <Schedule sx={{ color: "success.main",fontSize:{lg:"22px",md:'22px',sm:'22px',xs:"17px"} }} />
                        <GreenText sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>Scheduled Arrival</GreenText>
                        </Box>
                        <Typography sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>
                          {moment(
                            getEstArrivalTime(
                              trainStations,
                              trainDetail?.nextStationId,
                              trainDetail?.delay
                            ).find(
                              (item) =>
                                item?.StationDetailsId ===
                                station.StationDetailsId
                            )?.ArrivalTime,
                            "HH:mm"
                          ).format("hh:mm a")}
                        </Typography>
                      </InfoRow>
                      <InfoRow>
                      <Box sx={{display:"flex"}}>
                        <Train sx={{ color: "success.main",fontSize:{lg:"22px",md:'22px',sm:'22px',xs:"17px"} }} />
                        <GreenText sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>Estimated Arrival</GreenText>
                        </Box>
                        <Typography sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>
                          {
                            getEstArrivalTime(
                              trainStations,
                              trainDetail?.nextStationId,
                              trainDetail?.delay
                            ).find(
                              (item) =>
                                item?.StationDetailsId ==
                                station.StationDetailsId
                            )?.EstArrivalTime
                          }
                          
                        </Typography>
                      </InfoRow>
                      <InfoRow>
                      <Box sx={{display:"flex"}}>
                        <AccessTime sx={{ color: "success.main",fontSize:{lg:"22px",md:'22px',sm:'22px',xs:"17px"} }} />
                        <GreenText sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>Delay</GreenText>
                        </Box>
                        <Typography sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>
                          
                        {timeConvert(trainDetail?.delay)}
                        </Typography>
                      </InfoRow>
                      {index === 0 && (
                <InfoRow>
                  <Speed sx={{ color: "success.main",fontSize:{lg:"22px",md:'22px',sm:'22px',xs:"17px"} }} />
                  <GreenText sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>Current Speed</GreenText>
                  <Typography sx={{fontSize:{lg:"16px",md:'16px',sm:'16px',xs:"13px"}}}>
                  {trainDetail?.speed} km/hr
                  </Typography>
                </InfoRow>
              )}
                    </Stack>
                  </StationCard>
                ))}
              </TimelineContainer>
            </>
          ) : (
            <>
              <Typography sx={{ textAlign: "center", color: "black", mt: 32 }}>
                No live train found.
              </Typography>
            </>
          )
        )}
        </Box>
      </Container>
    </>
  );
};

export default TrainTrackingDropdown;
