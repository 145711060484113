import axios from "axios";

const server = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: server,
  // withCredentials:true
});

instance.interceptors.request.use((request) => {
  const token = localStorage.getItem("token");
  request.headers = {
    Accept: "application/json, text/plain, */*",
    'Authorization': `Bearer ${token}`,
    'api-key': process.env.REACT_APP_API_KEY, 
  }
  return request;
});

instance.interceptors.response.use((response) => {
  if (response) {
    return response;
  }
}, function (error) {
  return Promise.reject(error)
})

export default instance;